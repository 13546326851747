

@import url('https://fonts.googleapis.com/css?family=Mukta:400,600,700');

// Color variables
$white: #ffffff;
$light-yellow: #fef0d4;
$yellow: #fcba3c;
$dark: #1a213c;

$primary: $yellow;
$success: #54cc96;
$info: #78ceff;
$warning: #ffbe4e;
$danger: #ff5560;
$semi-bold: 600;
$bold: 700;

$font-size-xs: 0.7rem;
$font-size-md: 1rem;
$font-size-lg: 1.5rem;
$font-size-xl: 2rem;
$font-size-xxl: 3rem;
$muted:      #707070;

$lightdark5: #555555;
$lightgray: #dddddd;
$light: #eff3f6;
$lightdark3: #333333;
$lightdark4: #444444;
$light5: #f5f5f5;
$light3: #f3f3f3;
$light9: #f9f9f9;
$light-alt: #fafafa;
$lightdark7: #797979;
$lightdark-alt: #cccccc;

$bg-body: #f2f5f7;
$left-bar: $dark;
$left-bar-text: #8b6e3b;
$left-bar-text-active: #fcba3c;
$bg-topbar: $white;

$font-primary: 'Mukta', sans-serif;

$shadow: 1px 0px 20px rgba(0,0,0,0.05);


$base-font: 15px;

// Width variables
$width: 100%;


// Height variables
$height: 100%;

//Grid
$numberGridColumn: 5;
