.row-separator {
    height: 30px;
    width: 100%;
}

.image-crop-container {
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.image-crop {
    width: 120px;
    height: 120px;
    background: $gray-400;
    border-radius: 60px;
    overflow: hidden;
}
