.total-balance-container {
    border-bottom: 1px solid $gray-400;
    padding-bottom: 15px;
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
        border-right: 1px solid $gray-400;
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
        padding-right: 15px;
        height: 100%;
    }
}

.total-balance {
    color: $lightdark7;
}

.balance-container {
    font-size: $font-size-xxl;
    font-weight: $bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $left-bar;
}

.invest-progress-container {
    font-size: $font-size-lg;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.setting-title {
    font-size: 20px;
}

.setting-row {
    height: 100px;
    border-top: 1px solid $gray-400;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
        font-size: 22px;
    }

    .description {
        color: $gray-500;
        font-size: 13px;
    }
}
