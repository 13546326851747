// Color variables
$color-primary: #00f9c9;
$color-primary-transparent: #00f9c99e;
$color-second: #15009b;

.login.container-lg{
    height: 100%;
    max-width: 100% !important;
    padding: 0 !important;
    .bg-dark-color {
        background-color: $color-second !important;
        img.logo {
            width: 100%;
            max-width: 140px;
        }
    }
    @media (max-width: 767px){
        .bg-dark-color {
            padding-left: 1.5rem!important;
        }
        .register-borrower{
            height: 100% !important;
            #form-lang {
                padding-right: 25px !important;
                select.form-control {
                    border-color: #FFFFFF !important;
                    color: #FFFFFF !important;
                }
            }
        }
    }
    .card,.row{
        height: 100%;
    }
    .col-12.left-side{
        height: 100%;
        background: $color-second !important;
        .login-form, .borrower-form{
            padding: 0 20px;
            @media (min-width: 768px){
                padding: 0 15px 0 30px ;
            }
            form {
                button {
                    color: $color-second;
                    background-color: $color-primary;
                    border: 1px solid $color-primary;
                }
            }
        }
        @media (min-width:768px){
            padding: 0 15px;
        }
        @media (min-width:1199px){
            padding: 0 75px;
        }
        @media (min-width:1680px){
            padding: 0 125px 0 125px;
        }
        .borrower-form {
            color: $color-second;
            @media (min-width:768px){
                padding: 0 20px 0 30px;
                .title {
                    color: $color-second;
                }
            }
            @media (min-width:1199px){
                padding: 0%;
                .title {
                    color: $color-primary;
                }
            }
            .title {
                font-size: $h3-font-size;
                text-align: left;
            }
            a {
                color: $color-primary;
            }
        }

    }
    .col-12.right-side{
        color: $color-second !important;
        @media (min-width:768px){
            padding: 0 15px;
        }
        @media (min-width:1199px){
            padding: 0 15px ;
        }
        @media (min-width:1680px){
            padding: 0 95px 0 95px ;
        }
        &.inside{
            padding: 0 40px 0 40px;
            @media (min-width:768px){
                padding: 0 30px 0 15px;
                top:40px;
            }
            @media (min-width:1199px){
                padding: 0 15px 0 55px;

            }
            @media (min-width:1680px){
                padding: 0 95px 0 95px ;
            }
        }
    }

}
.title-sign{
    text-align: center;
    font-size: 30px !important;
    @media (min-width: 768px){
        font-size: 34px !important;
    }
    @media (min-width: 1360px){
        font-size: 50px !important;
    }
    @media (min-width: 1680px){
        font-size: 70px !important;
    }
}

.login-form, .borrower-form {
    background-color: $color-second !important;
    @include media-breakpoint-down(sm) {
        height: 100vh;
        background-color: white !important;
    }
    .title {
        margin-bottom: 45px;
        font-size: 30px !important;
        text-align: center;
        color: $color-second;
        @media (min-width: 768px){
            font-size: 34px !important;
        }
        @media (min-width: 1360px){
            font-size: 50px !important;
        }
        @media (min-width: 1680px){
            font-size: 70px !important;
        }
    }
    @include media-breakpoint-up(md) {
        label {
            color: white !important;
        }
        .title {
            font-size: $h3-font-size;
            color: $color-primary;
            text-align: left;
        }
        a {
            color: $color-primary;
        }
    }
}

#signup {
    a {
        width: 200px;
    }
}

.page-content-wrapper, #my-profile {
    .card {
        .card-body, .row {
            img.logo-dark {
                width: 100%;
                max-width: 140px;
            }
        }
        form {
            .form-control:focus {
                border-color: $color-second !important;
            }
            i.text-primary {
                color: $color-second !important;
            }
            .slider-login {
                span.irs-slider, span.irs-bar, span.irs-bar-edge{
                    background-color: $color-primary !important;
                }
            }
            .slider-loan {
                span.irs-slider, span.irs-bar, span.irs-bar-edge{
                    background-color: $color-second !important;
                }
            }
            button.btn {
                background-color: $color-primary;
                border: 1px solid $color-primary;
                color: $color-second;
            }
        }
    }
}

.side-menu {
    background-color: $color-second !important;
    #sidebar-menu {
        ul {
            li {
                a {
                    background-color: $color-second !important;
                    color: $color-primary-transparent;
                    &.active, &:hover {
                        color: $color-primary;
                        i {
                            color: $color-primary;
                        }
                    }
                }
            }
        }
    }
}

#my-profile, .content-page, .page-content-wrapper {
    .form-control:focus {
        border-color: $color-primary !important;
    }
    .btn {
        &.btn-primary {
            background-color: $color-primary;
            border: 1px solid $color-primary;
            color: $color-second;
        }
        &.btn-outline-primary{
            color: $color-second;
            border: 1px solid $color-second;

            i {
                color: $color-second !important;
            }
        }
        &.btn-outline-primary:hover{
            background-color: $color-primary !important;
        }
    }
    .wizard {
        .step-progressbar {
            .active {
                color: $color-second;
            }
            .active:before, .active:after {
                background-color: $color-second !important;
            }
        }
    }
}

#upload-document {
    .card {
        .dropzone-container{
            border: 1px $color-primary dashed;

        }
        .uploaded-file {
            .file-info{
                a {
                    color: $color-primary !important;
                }
                .file-type{
                    color: $color-primary !important;
                    border: 1px solid $color-primary;
                }
            }
        }
        i {
            &.text-primary{
                color: $color-primary !important;
            }
        }
    }
}
