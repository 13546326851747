

/* ==============
  pricing
===================*/
.pricing-box{
    box-shadow: $shadow;
    .pricing-title {
        clip-path: polygon(100% 0, 100% 75%, 50% 100%, 0% 75%, 0 0);
    }
    .price {
        display: inline-block;
        width: 108px;
        height: 108px;
        line-height: 102px;
        border-radius: 50%;
        color: $white;
        border: 3px solid $white;
        position: absolute;
        margin: 0px auto;
        left: 0px;
        right: 0px;
        top: 140px;
    }
    .plan-features{
        p{
            font-size: 16px;
        }
    }
}

/* ==============
  faq
===================*/

.faq-box{
    .faq-ques{
        background: rgba($primary, 0.2);
    }
    .faq-icon{  
        font-size: 30px;
        text-shadow: rgba($primary, 0.3) 1px 1px, rgba($primary, 0.2) 2px 2px, rgba($primary, 0.3) 3px 3px;
        position: relative;
        bottom: 13px;
        left: 13px;
    }
}

/* ==============
  comming soon
===================*/

.comming-watch{
    div {
        display: inline-block;
        .card{
            margin: 0px 15px 15px 15px;
            .countdown-num
            {
                font-weight: 600;
                color: $primary;
            }
        }
        span {
            width: 150px;
            display: block;
            &:first-child {
                height: 60px;
                font-weight: 300;
                font-size: 3em;
                line-height: 48px;
            }
            &:last-child {
                padding-top: 14px;
                font-size: 0.9em;
            }
        }        
    }
}
