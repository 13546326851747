.swal2-styled {
    &.swal2-confirm {
        background: $primary !important;

        &:focus {
            box-shadow: none;
        }
    }
}

.swal2-icon {
    &.swal2-success {
        .swal2-success-ring { border-color: $primary !important; }
    }

    &.swal2-success [class^=swal2-success-line] { background-color: $primary !important; }

    &.swal2-error { border-color: $danger !important; }
    &.swal2-error [class^=swal2-x-mark-line] { background-color: $danger !important; }
}
