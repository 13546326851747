/* ==============
  Bootstrap-custom
===================*/

// Breacrumb
.breadcrumb {
  background-color: transparent;
  margin-bottom: 12px;
  margin-top: 10px;
  padding: .75rem 0rem;
  li{
    font-size: 14px;
    a{
      color: $dark;
    }
  }
}

.breadcrumb>li+li:before {
  padding: 0 5px;
  color: $muted;
  content: "\f105" !important;
  font-family: FontAwesome;
}

//Dropdown
.dropdown-menu {
  padding: 4px 0;
  font-size: 15px;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.05);
  border-color: $light;
}

.dropdown-item.active, .dropdown-item:active {
  color: $dark;
  text-decoration: none;
  background-color: $light;
}

.dropdown-item {
  padding: 6px 1.5rem;
}

.dropdown-menu-animated {
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  margin-top: 20px!important;
}
.show > .dropdown-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0 !important;
}

// Card
.card {
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.05);
  border: none;
}


//Background color
.bg-primary {
  background-color: $primary !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-info {
  background-color: $info !important;
}

.bg-warning {
  background-color: $warning !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-muted {
  background-color: $muted !important;
}

.bg-white {
  background-color: $white !important;
}


//Text Color
.text-white {
  color: $white !important;
}

.text-danger {
  color: $danger !important;
}

.text-muted {
  color: $muted !important;
}

.text-primary {
  color: $primary !important;
}

.text-warning {
  color: $warning !important;
}

.text-success {
  color: $success !important;
}

.text-info {
  color: $info !important;
}

.text-dark {
  color: $dark !important;
}


//Badge
.badge-default {
  background-color: $light;
  color: $dark;
}

.badge-primary {
  background-color: $primary;
}

.badge-success {
  background-color: $success;
}

.badge-info {
  background-color: $info;
}

.badge-warning {
  background-color: $warning;
  color: $white;
}

.badge-danger {
  background-color: $danger;
}

.badge-dark {
  background-color: $dark;
}

/* Navs & Tabs */
.nav-pills .nav-item.show .nav-link, .nav-pills .nav-link.active {
  background-color: $primary;
}

.nav-pills > .active > a > .badge {
  color: $primary;
}

// blockquote

.blockquote {
  padding: 10px 20px;
  margin-bottom: 20px;
  border-left: 4px solid $lightgray;
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid $lightgray;
  text-align: right;
}

// custom-checkbox

.custom-checkbox {
  .custom-control-input:checked~.custom-control-label{
    &:before{
      background: $primary;
    }
  }
}

.addon-icon.text-primary.is-invalid {
    right: 35px;
}
