/* ==============
  Account Pages
===================*/

.accountbg{
  position: relative;
  height: 100vh;
  background: url("../images/bg-1.jpg");
  background-size: cover;
  background-position: center;
  .content-center {
    display: table;
    width: 100%;
    height: 100%;
  }
  
  .content-desc-center {
    display: table-cell;
    vertical-align: middle;
  }
  .logo-admin{
    line-height: 70px;
  }
  .user-thumb {
    img{
      height: 88px;
      width: 88px;
    }
  }
}

.ex-page-content {
  h1 {
    font-size: 98px;
    font-weight: 500;
    line-height: 150px;
    text-shadow: rgba($primary, 0.3) 1px 1px, rgba($primary, 0.2) 2px 2px, rgba($primary, 0.3) 3px 3px;
    i{
      font-size: 84px;
    }
  }
}



