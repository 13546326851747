/*
Template Name: Drixo - Bootstrap 4 Admin Dashboard
Author: Themesdesign
Website: www.themesdesign.in
File: Main Css File
*/

/*
01. General
02. Loader
03. Bootstrap Custom
04. Helper
05. Waves Effect
06. Menu
08. Buttons
09. Summernote
10. Alerts
11. Demo Only
12. Pagination
13. Progress
14. Popover & Tooltips
15. Nestable
16. Sweet alert
17. Alertify
18. Form Elements
19. Form Advanced
20. Form Validation
21. Form Editor
22. Form Uploads
23. Calendar
24. Tables
25. Range-slider
26. Charts
27. Widgets
28. Maps
29. Timeline
30. Invoice
31. Account Pages
32. Extras Pages
33. Responsive
*/

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "wit";

@import "variables";
@import "general";
@import "loader";
@import "bootstrap-custom";
@import "helper";
@import "waves";
@import "menu";
@import "buttons";
@import "summernote";
@import "alerts";
@import "demo-only";
@import "pagination";
@import "progressbar";
@import "popover-tooltips";
@import "nestable";
@import "sweet-alert";
@import "alertify";
@import "form-elements";
@import "form-advanced";
@import "form-validation";
@import "form-editor";
@import "form-upload";
@import "calendar";
@import "tables";
@import "range-slider";
@import "charts";
@import "widgets";
@import "maps";
@import "timeline";
@import "invoice";
@import "account-pages";
@import "extras-pages";
@import "responsive";
@import "grid";

@import "_dashboard";
@import "rangeSliderSkin";
@import "borrower-form";
@import "loan-calculator";
@import "profile";
@import "step";
@import "document-upload";
@import "sweetalert2";
@import "project-setup";
@import "or-separator";
@import "investor-form";


