
.or-separator {

    hr {
        width: 38%;
        float: left;
    }

    hr:first-child {
        float: left;
    }

    hr:last-child {
        float: right;
    }

    .or-text {
        display: inline-block;
        width: 24%;
        text-align: center;
        margin-top: 0px;
    }

    @media (max-width: 500px){
        &.lang-it,
        &.lang-de  {
            hr {
                width: 30%;
            }

            .or-text {
                width: 40%;
            }
        }
    }

    @media (min-width: 991px){
        hr {
            width: 40%;
        }

        .or-text {
            width: 20%;
        }
    }

    @media (min-width: 1200px){
        hr {
            width: 47%;
        }

        .or-text {
            width: 6%;
        }

        &.lang-it,
        &.lang-de {
            hr {
                width: 44%;
            }

            .or-text {
                width: 12%;
            }
        }
    }


    &.in-login-form {
        hr {
            background-color: #fdba3c;
        }

        .or-text {
            color: #fdba3c;
        }

        @media (min-width: 768px){
            hr {
                width: 40%;
            }

            .or-text {
                width: 20%;
            }
        }

        @media (min-width: 1200px){
            hr {
                width: 40%;
            }

            .or-text {
                width: 20%;
            }
        }
    }

    &.lang-it,
    &.lang-de {
        &.in-login-form {
            @media (min-width: 768px){
                hr {
                    width: 34%;
                }

                .or-text {
                    width: 32%;
                }
            }

            @media (min-width: 1200px){
                hr {
                    width: 37%;
                }

                .or-text {
                    width: 26%;
                }
            }
        }
    }
}
