.grid {
    display: grid;
}

.gap-y-4 {
    row-gap: 1rem;
}

.gap-y-8 {
    row-gap: 2rem;
}

.gap-x-4 {
    column-gap: 1rem;
}

.gap-x-8 {
    column-gap: 2rem;
}

@for $i from 1 through $numberGridColumn {
    .grid-cols-#{$i} {
        grid-template-columns: repeat($i, minmax(0,1fr));
    }
}

@include media-breakpoint-up(sm) {
    @for $i from 1 through $numberGridColumn {
        .sm\:grid-cols-#{$i} {
            grid-template-columns: repeat($i, minmax(0,1fr));
        }
    }
}

@include media-breakpoint-up(md) {
    @for $i from 1 through $numberGridColumn {
        .md\:grid-cols-#{$i} {
            grid-template-columns: repeat($i, minmax(0,1fr));
        }
    }
}

@include media-breakpoint-up(md) {
    @for $i from 1 through $numberGridColumn {
        .lg\:grid-cols-#{$i} {
            grid-template-columns: repeat($i, minmax(0,1fr));
        }
    }
}
