/* =========== */
/* Sweet Alert */
/* =========== */

.swal2-modal {
    font-family: Mukta,sans-serif !important;

  .swal2-title {
    font-size: 28px;
  }
  .swal2-content {
    font-size: 16px;
  }
  .swal2-spacer {
    margin: 10px 0;
  }
  .swal2-file, .swal2-input, .swal2-textarea {
    border: 2px solid $muted;
    font-size: 16px;
    box-shadow: none !important;
  }
  .swal2-styled {
    border: inherit;
  }
}

.swal2-icon.swal2-question {
  color: $primary;
  border-color: $primary;
}

.swal2-icon.swal2-success {
  border-color: $success;

  .line {
    background-color: $success;
  }

  .placeholder {
    border-color: $success;
  }
}

.swal2-icon.swal2-warning {
  color: $warning;
  border-color: $warning;
}

.swal2-icon.swal2-error {
  border-color: $danger;
  .line {
    background-color: $danger;
  }
}
.swal2-modal .swal2-file:focus, .swal2-modal .swal2-input:focus, .swal2-modal .swal2-textarea:focus {
  outline: 0;
  border: 2px solid $primary;
}
