/* ==============
  Timeline
===================*/

.cd-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}
.cd-container::after {
  content: '';
  display: table;
  clear: both;
}
#cd-timeline {
  margin-bottom: 2em;
  margin-top: 2em;
  padding: 2em 0;
  position: relative;
  &::before {
    border-left: 3px solid $light;
    content: '';
    height: 100%;
    left: 18px;
    position: absolute;
    top: 0;
    width: 3px;
  }
}
@media only screen and (min-width: 1170px) {
  #cd-timeline {
    margin-bottom: 3em;
    margin-top: 3em;
    &::before {
      left: 50%;
      margin-left: -2px;
    }
  }
}

.cd-timeline-block {
  margin: 2em 0;
  position: relative;
  &:after {
    clear: both;
    content: "";
    display: table;
  }
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}
.cd-timeline-img {
  position: absolute;
  top: 20px;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  font-size: 20px;
  color: $white;
  background-color: $primary;
  border: 5px solid $white;
  z-index: 99;
  i {
    margin-left: 1px;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 40px;
    height: 40px;
    line-height: 32px;
    left: 50%;
    top: 0px;
    margin-left: -20px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    -moz-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }
}

.cd-timeline-box {
  border-radius: 5px;
  border: 2px solid $light;
  margin-left: 60px;
  padding: 1em;
  position: relative;

  &:after {
    clear: both;
    content: "";
    display: table;
  }
  h2 {
    margin-top: 0;
  }
  .cd-date {
    display: inline-block;
    font-size: 14px;
  }
  h3 {
    font-size: 18px;
    margin: 0 0 15px 0;
  }
}

.cd-timeline-box .cd-date {
  float: left;
  padding: .8em 0;
  opacity: .7;
}
.cd-timeline-box::before {
  content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 7px;
    width: 100%;
    background: $light;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-box {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
    margin-top: 70px;
    float: left;
    &:before {
      top: -54px;
      left: 7%;
      right: auto;
    }

    .cd-date {
      position: absolute;
      width: 100%;
      left: -18px;
      top: -45px;
    }
    .cd-timeline-content{
      &:before{
        content: "";
        position: absolute;
        top: -49px;
        left: 75px;
        right: 45px;
        height: 50px;
        border-left: 12px double $light;
        border-right: 12px double $light;
      }
    }
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-box {
    float: right;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-box .cd-date {
    left: auto;
    right: 50%;
    text-align: right;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-box.right {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
    margin-top: 70px;
    float: left;
    &:before {
      top: -54px;
      left: auto;
      right: 7%;
    }
    .cd-timeline-content{
      &:before{
        left: 45px;
        right: 75px;
      }
    }
  }
}

@media (max-width: 1024px){
  .cd-timeline-box{
    &:before{
      width: 10%
    }
  }
  .cd-timeline-img{
    top: 2px;
  }
}