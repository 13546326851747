.wizard {
    text-align: center;
    position: relative;
    margin-top: 20px;
    z-index: 2;

    .step-progressbar {
        margin-bottom: 30px;
        overflow: hidden;
        color: lightgrey;

        @include media-breakpoint-down(md) {
            padding: 0 !important;
        }

        .active {
            color: $primary;
        }

        li {
            list-style-type: none;
            font-size: 12px;
            width: 14%;
            float: left;
            position: relative;

            &:before {
                width: 30px;
                height: 30px;
                line-height: 45px;
                display: block;
                font-size: 18px;
                color: #ffffff;
                background: lightgray;
                border-radius: 50%;
                margin: 12.5px auto 10px auto;
                padding: 2px;
                background-size: auto 15px !important;
            }

            &:after {
                content: '';
                width: 100%;
                height: 2px;
                background: lightgray;
                position: absolute;
                left: 0;
                top: 25px;
                z-index: -1
            }

            &.active:after {
                background: $primary
            }
        }

        .loan-calculator {
            &:before {
                content: '';
                background: url('../images/calculator.png') no-repeat center, lightgrey;
            }

            &.active:before {
                content: '';
                background: url('../images/calculator.png') no-repeat center, $primary;
            }
        }

        .business-and-trade {
            &:before {
                content: '';
                background: url('../images/business-and-trade.png') no-repeat center, lightgrey;
            }

            &.active:before {
                content: '';
                background: url('../images/business-and-trade.png') no-repeat center, $primary;
            }
        }

        .user {
            &:before {
                content: '';
                background: url('../images/user.png') no-repeat center, lightgrey;
            }

            &.active:before {
                content: '';
                background: url('../images/user.png') no-repeat center, $primary;
            }
        }

        .question {
            &:before {
                content: '';
                background: url('../images/question.png') no-repeat center, lightgrey;
            }

            &.active:before {
                content: '';
                background: url('../images/question.png') no-repeat center, $primary;
            }
        }

        .document {
            &:before {
                content: '';
                background: url('../images/document.png') no-repeat center, lightgrey;
            }

            &.active:before {
                content: '';
                background: url('../images/document.png') no-repeat center, $primary;
            }
        }

        .percent-tag {
            &:before {
                content: '';
                background: url('../images/percent.png') no-repeat center, lightgrey;
            }

            &.active:before {
                content: '';
                background: url('../images/percent.png') no-repeat center, $primary;
            }
        }

        .hand-sake {
            &:before {
                content: '';
                background: url('../images/hand-shake.png') no-repeat center, lightgrey;
            }

            &.active:before {
                content: '';
                background: url('../images/hand-shake.png') no-repeat center, $primary;
            }
        }
    }
}

