/* ==============
  General
===================*/

body {
  background: $bg-body;
  font-family: $font-primary;
  margin: 0;
  font-size: $base-font;
  color: #4e4a4a;
}
html {
  overflow-x: hidden;
  position: relative;
  min-height: 100%;
  background: $bg-body;
}
h1, h2, h3, h4, h5, h6 {
  margin: 10px 0;
  font-weight: 600;
  font-family: $font-primary;
}

h1 {
  line-height: 43px;
}

h2 {
  line-height: 35px;
}

h3 {
  line-height: 30px;
  small {
    color: $lightdark4;
  }
}

h4 {
  line-height: 22px;
  small {
    color: $lightdark4;
  }
}

h5 {
  small {
    color: $lightdark4;
  }
}

.disabled {
    .react-tel-input .flag-dropdown {
        position: absolute;
        top: 0;
        bottom: 0;
        padding: 0;
        background-color: transparent;
        border: 0;
    }

    .form-control {
        padding-left: 30px !important;
    }

    .arrow {
        display: none;
    }
}

* {
  outline: none !important;
}

.input-group {
    position: relative;
    width: 100%;

    input {
        z-index: 0;
        position: absolute;
        left: 0;
        width: 100%;
        border-radius: 0.25rem !important;
    }

    .addon-icon {
        position: absolute;
        top: 10px;
        right: 15px;
        z-index: 10000;
        i {
            cursor: pointer;
            @extend .text-primary;
        }
    }
}

i {
    &.tick-cross {
        &:before {
            content: 'S';
        }

        &.failed:before {
            content: '9';
        }

        &:hover {
            &:before {
                content: '9';
            }
        }
    }
}
.bg-dark-color {
    background-color: $dark !important;
}
a {
  &:hover {
    outline: 0;
    text-decoration: none;
  }
  &:active {
    outline: 0;
    text-decoration: none;
  }
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}

code {
  color: $info;
}


.container-alt {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.footer {
  background-color: $light9;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  bottom: 0;
  color: $dark;
  text-align: center;
  padding: 20px 30px;
  //position: fixed;
  right: 0;
  left: 240px;
  z-index: 2;
}

#wrapper {
  height: $height;
  width: $width;
    overflow-x:hidden;
     .content{
        position: relative;
        z-index: 1;
        @media (max-width: 1680px) {
            padding-bottom: 50px;
        }
    }
}

.text-lg {
  font-size: $font-size-lg;
}

.text-md {
    font-size: $font-size-md;
}

.text-xl {
  font-size: $font-size-xl;
}

.rounded-full {
  border-radius: 19px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  padding: 5.5px 15px;
  border: 1px solid $dark;
  background-color: white;
}

.text-green {
    color: $green;
}
.invalid-feedback {
    display: block !important;
}

.text-disabled {
    color: rgb(170, 170, 170);
}

.text-xs {
    font-size: 12px;
}

.card-alert-info {
    padding: 100px 30px !important;
}

.border-radius-5 {
    border: 1px solid $gray-200;
    border-radius: 5px;
}

.circle-front {
    display: flex;
    align-items: center;
    line-height: 100%;

    &::before{
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        background-color: #69b6d5;
    }

    &.color-success::before {
        background-color: $success;
    }

    &.color-primary::before {
        background-color: $primary;
    }

    &.color-secondar::before {
        background-color: $secondary;
    }
}

.warning-close {
    padding:2.5px;
    text-align: center;
    width: 20px;
    height: 20px;
    line-height: 100%;
    border-radius: 50%;
    background: $danger;
    color: white;
}

.link {
    cursor: pointer;
}

.alert-sm {
    padding: 3px 10px;
    line-height: 90%;
    border-radius: 15px;
    font-size: 14px;
}

.min-vh-60 {
    min-height: 60vh;
}

.link-project-detail {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.react-tel-input .form-control {
    width: 100% !important;
}

#upload-document .card .card-body .text-center h6{
    min-height: 40px;
}

.color-e::before{
    background-color: #c31a17;
}
.color-d::before{
    background-color: #e15d0f;
}
.color-c::before{
    background-color: #e8da23;
}
.color-b::before,.color-c-plus::before{
    background-color: #a6c83a;
}
.color-b-plus::before{
    background-color: #6ab42f;
}
.color-a::before{
    background-color: #509c4a;
}
.color-a-plus::before{
    background-color: #3a7b42;
}

.swal2-container:not(.swal2-in){
    pointer-events: all !important;
}

.swal2-icon.swal2-info.swal2-icon-show {
    .swal2-icon-content {
        font-size: 1em !important;;
    }
}
