
.total-balance-container #amount_loan,.total-balance-container .irs{
    display: none;
    opacity: 0;
    visibility: hidden;
}

.register-borrower {
   @media (min-width: 768px){
       .irs-line-mid, .irs-line, .irs-line-left, .irs-line-right{
           background: rgba(255,255,255,0.1) !important;
       }

       .irs-bar,.irs-bar-edge{
           background: rgba(255,255,255,1) !important;
       }
   }

    .irs-line-mid{
        left: 0 !important;
        width: 100% !important;
    }

    .irs-slider.single{
        width: 10px !important;
        height: 40px !important;
        border-radius: 5px !important;
        top:12px !important;
    }

    .irs-line-left, .irs-line-right{
        width: 0 !important;
    }


    select.form-control{
        background: transparent;
        border:1px solid $dark;
        color: $dark;
        padding-right:15px;
        option{
            color:#000;
        }
      @media (min-width: 768px){
          border:1px solid #fff;
          color:$white;
      }
    }
    .section-detail{
        .label,.second-label{
            font-size: 20px;
            @media (min-width: 768px) {
                font-size: 30px;
            }
        }
        .label-small{
            color:$dark;
            font-size: 12px;
            @media (min-width: 768px) {
                font-size:15px;
            }
        }
    }
    .borrower-form {
        background-color: $dark;

        @include media-breakpoint-down(sm) {
            height: 100vh;
            background-color: white;
        }

        .title {
            margin-bottom: 45px;
            font-size: 30px !important;
            text-align: center;
            @media (min-width: 768px){
                font-size: 34px !important;
            }
            @media (min-width: 1360px){
                font-size: 50px !important;
            }
            @media (min-width: 1680px){
                font-size: 70px !important;
            }
        }

        @include media-breakpoint-up(md) {
            label {
                color: white !important;
            }

            .title {
                font-size: $h3-font-size;
                color: white;
                text-align: left;
            }

            a {
                color: white;
            }
        }
        label{
            font-size: 20px;
            @media (min-width: 768px) {
                font-size: 30px;
            }

        }
    }

    .request-title {
        margin-bottom: 45px;
        font-size: 30px !important;
        text-align: center;
        @media (min-width: 768px){
            font-size: 34px !important;
        }
        @media (min-width: 1360px){
            font-size: 50px !important;
        }
        @media (min-width: 1680px){
            font-size: 70px !important;
        }

        @include media-breakpoint-up(md) {
            text-align: left;
            font-size: $h3-font-size;
        }
    }
}
