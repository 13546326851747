.loan-calculator-form {
    title {
        font-size: $h3-font-size;
    }
}

html.background,#wrapper.background{
    background: #fff;
}
.company-detail.container,.company-detail.container-lg{
    max-width: 1680px;
    &.first{
        .form-group{
            label{
                font-size: 20px;
                @media (min-width: 768px) {
                    font-size: 30px;
                }
            }
        }
        .section-detail{
            .label,.second-label{
                font-size: 20px;
                @media (min-width: 768px) {
                    font-size: 30px;
                }
            }
            .label-small{
                color:$dark;
                font-size: 12px;
                @media (min-width: 768px) {
                    font-size:15px;
                }
            }
        }
    }
        .irs-line-mid, .irs-line, .irs-line-left, .irs-line-right{
            background: #F1F1F1 !important;
        }

        .irs-bar,.irs-bar-edge{
            background: $dark !important;
        }

    .irs-line-mid{
        left: 0 !important;
        width: 100% !important;
    }

    .irs-slider.single{
        width: 10px !important;
        height: 40px !important;
        border-radius: 5px !important;
        top:12px !important;
    }

    .irs-line-left, .irs-line-right{
        width: 0 !important;
    }

    select.form-control{
        background: transparent;
        border:1px solid $dark;
        color:#000;
        padding-right:15px;
        option{
            color:#000;
        }
    }

    .company-detail-header{
       h2{
           color:$dark !important;
           font-size: 30px !important;
       }
        @media (min-width: 768px) {
            h2{
                font-size: 34px !important;
                width: 30%;
            }
            .steps-wrapper{
                width: 70%;
            }
        }
        @media (min-width: 1199px) {
            h2 {
                font-size: 50px !important;
            }
        }
        @media (min-width: 1680px) {
            h2 {
                font-size: 60px !important;
            }
        }
    }

}
