/*
File: Menu
*/

.topbar {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;

  .topbar-left {
    float: left;
    text-align: center;
    height: 70px;
    position: relative;
    width: 240px;
    z-index: 1;
    background-color: $left-bar;
    .logo{
      line-height: 70px;
    }
  }
}

.has_sub.nav-active i.mdi-chevron-right:before {
  content: "\F140";
}

.navbar-custom {
  background-color: $bg-topbar;
  border: none;
  box-shadow: $shadow;
  padding: 0px 10px;
}

/* Notification */
.notification-list {
  margin: 0 !important;

  .dropdown-menu.dropdown-menu-right {
    -webkit-transform: none !important;
    transform: none !important;
    top: 100% !important;
    right: 0 !important;
    left: auto !important;
  }

  .noti-title {
    border-radius: 0.25rem 0.25rem 0 0;
    margin: -4px 0px 0px 0px;
    width: auto;
    padding: 12px 20px;

    h5 {
      margin: 0;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .noti-icon {
    font-size: 24px;
    padding: 0 10px;
    vertical-align: middle;
    color: $white;
  }
  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 9px;
  }
  .notify-item {
    padding: 15px 20px;

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 15px;
      border-radius: 50%;
      color: $white;

      img {
        margin-top: 4px;
      }
    }
    .notify-details {
      margin-bottom: 0;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;

      b {
        font-weight: 500;
      }

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }
  }
  .notify-all {
    border-radius: 0 0 0.25rem 0.25rem;
    margin: 0 0 -5px 0;
  }
  .nav-link {
    padding: 0 5px;
    line-height: 70px;
  }
}

.profile-dropdown {
  width: 170px;
  i {
    font-size: 17px;
    margin-right: 5px;
  }
}

.app-search {
  margin-top: 18px;
  position: relative;
  .form-control,
  .form-control:focus {
      border: 1px solid rgba($white, 0.1);
      font-size: 13px;
      height: 34px;
      padding-left: 18px;
      padding-right: 40px;
      margin-right: 20px;
      background: rgba($white, 0.1);
      box-shadow: none;
      border-radius: 30px;
      width: 200px;
      color: $white;
  }
  button {
      position: absolute;
      top: 8px;
      right: 30px;
      display: block;
      color: rgba($white, 0.5);
      font-size: 11px;
      border: none;
      background-color: transparent;
  }
}

.app-search input::-webkit-input-placeholder {
  color: $white;
}

.app-search input:-moz-placeholder {
  color: $white;
}

.app-search input::-moz-placeholder {
  color: $white;
}

.app-search input:-ms-input-placeholder {
  color: $white;
}

.user-no-image{
    display: block;
    width:36px;
    height:36px;
    border-radius: 100%;
    background: $dark;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.nav-user {
  margin: 0 15px;
  img {
    height:36px;
    width: 36px;
  }
}

.arrow-none:after {
  border: none;
  margin: 0;
  display: none;
}


.menu-title {
  padding: 12px 25px !important;
  letter-spacing: .035em;
  pointer-events: none;
  cursor: default;
  font-size: 13px;
  color: $left-bar-text;
}

.dropdown-menu-lg {
  width: 300px;
}

.side-menu {
  bottom: 0;
  top: 0;
  width: 240px;
  padding-bottom: 20px;
  transition: all 0.4s ease-in-out;
  background: $left-bar;
  position: absolute;
  z-index: 99;
  box-shadow: $shadow;
  .left-side-logo{
    .logo{
      line-height: 70px;
    }
  }
}

body.fixed-left {
  .side-menu.left {
    bottom: 50px;
    height: $height;
    margin-bottom: -70px;
    margin-top: 0;
    padding-bottom: 70px;
    position: fixed;
  }
}

.content-page {
  margin-left: 240px;
  overflow: hidden;
  min-height: 500px;
}

.content-page > .content {
  margin-top: 70px;
  margin-bottom: 63px;
  padding: 0 10px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.page-content-wrapper {
  margin: 0 -10px;
}

.button-menu-mobile{
  background-color: $bg-topbar;
  font-size: 28px;
  color: $dark;
  width: 60px;
  border: none;
  line-height: 68px;
  cursor: pointer;
}
.button-menu-mobile-topbar {
  display: none !important;
}

.sidebar-inner {
  height: $height;
}

#sidebar-menu, #sidebar-menu ul, #sidebar-menu li, #sidebar-menu a {
  border: 0;
  font-weight: normal;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
}

#sidebar-menu {
  padding-bottom: 50px;
  width: 100%;
  .badge {
    margin-top: 4px;
  }
  ul {
    ul {
      display: none;
      li {
        border-top: 0;
      }
      li.active {
        a {
          color: $primary;
        }
      }
      a {
        color: rgba($left-bar-text-active,0.8);
        display: block;
        padding: 12px 25px 12px 60px;
        &:hover {
          color: $left-bar-text-active;
        }
      }
    }
  }
}

#sidebar-menu > ul > li > a {
  color: $left-bar-text;
  display: block;
  padding: 14px 25px;
  background-color: $left-bar;
  font-size: 15.5px;

  &:hover {
    color: $left-bar-text-active;
    text-decoration: none;
  }

  span {
    i {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

#sidebar-menu > ul > li.nav-active > a {
  background-color: transparent;
}
#sidebar-menu > ul > li > a > span {
  vertical-align: middle;
}

#sidebar-menu > ul > li > a > i {
  display: inline-block;
  line-height: 17px;
  margin-left: 3px;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
}

#sidebar-menu > ul > li > a > i.i-right {
  float: right;
  margin: 3px 0 0 0;
}

#sidebar-menu > ul > li > a.active {
  color: $left-bar-text-active;

  i {
    color: $left-bar-text-active;
  }
}

#sidebar-menu > ul > li.nav-active > ul {
  display: block;
}


// enlarged

#wrapper.enlarged {

  .left.side-menu {
    padding-top: 0;
    z-index: 5;
    margin-left: -100%;
  }
  .content-page {
    margin-left: 0;
  }
}

#wrapper.enlarged .footer {
    left: 0;
}

.user-details {
  min-height: 80px;
  padding: 20px;
  position: relative;
  img {
    position: relative;
    z-index: 9999;
    height: 64px;
    width: 64px;
  }
  .user-info {
    text-align: center;

    .user-status {
      display: inline-block;
      padding: 3px 10px;
      border: 1px solid rgba(34, 34, 34, 0.27);
      border-radius: 20px;
      font-size: 12px;
    }
  }
}

// page title

.page-title {
  font-size: 18px;
  margin-top: 22px;
}
