/* ==== =====
Invoice
============= */

.table > thead > tr > .no-line {
  border-bottom: none;
}

.table > tbody > tr > .no-line {
  border-top: none;
}
.table > tbody > tr > .thick-line {
  border-top: 2px solid $lightgray;
}

/* ==============
  Print css
===================*/
@media print {
  .content {
    margin-top: 0;
    padding-top: 0;
  }
  .content-page {
    margin-left: 0 !important;
    margin-top: 0 ;
  }
  .container-fluid {
    width: 100%;
  }
}