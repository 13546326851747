.dropzone-container {
    border: 1px $primary dashed;
    border-radius: 5px;
    width: 100%;
    margin: 20px 0;
    padding: 30px 0;
    background: $gray-100;

    .icon {
        font-size: 50px;
    }
    .dropzone-instruction {
        font-size: $font-size-xs;
        color: $gray-500;
    }
}

.upload-tip {
    font-size: 14px;
    padding-bottom: 20px;
}

.uploaded-file {
    font-size: 13px;
    color: $gray-600;

    .files {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        i {
            &.link {
                &:before {
                    content: 'S';
                }

                &.failed:before {
                    content: '9';
                }

                &:hover {
                    &:before {
                        content: '9';
                    }
                }
            }
        }

        .file-info {
            display: flex;
            justify-content: flex-start;
            word-break: break-all;
            .file-type {
                width: 40px;
                height: 40px;
                color: $primary;
                border-radius: 20px;
                border: 1px solid $primary;
                background: $gray-100;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
            }

            .file-name {
                font-size: 14px;
            }

            .file-size {
                font-size: $font-size-xs;
                color: $gray-500;
            }
        }
    }
}

.upload-document-instruction {
    font-size: $font-size-xs;
    color: $gray-500;
}

.file-info {
    a {
        color: $primary;
    }
}
